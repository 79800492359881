import React, { Component } from "react";
import Type from "prop-types";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { JL } from "jsnlog";
import isToday from "dayjs/plugin/isToday";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import relativeTime from "dayjs/plugin/relativeTime";
import dayjs from "dayjs";
import { Helmet } from "react-helmet";

import WidgetZendesk from "./containers/WidgetZendesk";
import ErrorBoundary from "./containers/ErrorBoundary";
import InternalServerErrorPage from "./components/InternalServerErrorPage";
import WidgetProductFruits from "./containers/WidgetProductFruits";

import { theme } from "./muiTheme";
import { store } from "./redux/configureStore";
import { authService, logErrorsService, urlHelpers } from "./core";
import { constants } from "./core";
import SignalRProvider from "./SignalRProvider";
import { logErrorsApi } from "./api/logErrorsApi";
import { localStorageSyncApi } from "./core/localStorageSyncApi";
import { sessionStorageSyncApi } from "./core/sessionStorageSyncApi";
import { AppUiProvider } from "./utils/AppUiProvider";
import { Routes } from "./routes/Routes";
import { RootLayout } from "./containers/LayoutMain/RootLayout";

if (constants.IS_PRODUCTION) {
  require("./inspectlet");
}

const scripts = [];

if (process.env.REACT_APP_CLARITY_PROJECT_ID) {
  scripts.push({
    type: "text/javascript",
    innerHTML: `(function(c,l,a,r,i,t,y){
c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "${process.env.REACT_APP_CLARITY_PROJECT_ID}");`,
  });
}

if (process.env.REACT_APP_USER_GUIDING_ID) {
  scripts.push({
    type: "text/javascript",
    innerHTML: `(function(g,u,i,d,e,s){g[e]=g[e]||[];var f=u.getElementsByTagName(i)[0];
      var k=u.createElement(i);k.async=true;k.src='https://static.userguiding.com/media/user-guiding-'+s+'-embedded.js';
      f.parentNode.insertBefore(k,f);if(g[d])return;var ug=g[d]={q:[]};ug.c=function(n){
        return function(){ug.q.push([n,arguments])};};
        var m=['previewGuide','finishPreview','track','identify','hideChecklist','launchChecklist'];
        for(var j=0;j<m.length;j+=1){ug[m[j]]=ug.c(m[j]);}})(window,document,'script','userGuiding','userGuidingLayer',
        '${process.env.REACT_APP_USER_GUIDING_ID}');`,
  });
}

dayjs.extend(isToday);
dayjs.extend(quarterOfYear);
dayjs.extend(relativeTime);

const history = createBrowserHistory();

class App extends Component {
  static propTypes = {
    classes: Type.object,
  };

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    const url = window.location.href;
    info = JSON.stringify({ info, error });
    logErrorsService.error(error.message, info);
    logErrorsApi.push(error.message, url, info);
    console.error("Chunk loading failed:", error, info);
    if (error && /Loading chunk \d+ failed/.test(error.message)) {
      window.location.reload();
    }
  }

  render() {
    console.log("REACT_APP_ENV", process.env.REACT_APP_ENV);
    console.log("HOST_URL", constants.HOST_URL);
    console.log("Version", process.env.REACT_APP_VERSION);
    if (this.state.hasError) {
      return <InternalServerErrorPage />;
    }

    let url = urlHelpers.getAbsoluteURL("/jsnlog.logger");
    const token = authService.getToken();
    var beforeSendExample = function (xhr) {
      const bfp = localStorageSyncApi.getOrSetBfp();
      const sfp = sessionStorageSyncApi.getOrSetSfp();
      xhr.setRequestHeader("jwtGuid", authService.getJwtGuid());
      xhr.setRequestHeader("browserHref", window.location.href);
      xhr.setRequestHeader("Authorization", `Bearer ${token}`);
      xhr.setRequestHeader("x-react-app-version", process.env.REACT_APP_VERSION);
      xhr.setRequestHeader("bfp", bfp);
      xhr.setRequestHeader("sfp", sfp);
    };
    JL.setOptions({
      defaultAjaxUrl: url,
      storeInBufferLevel: JL.getDebugLevel(),
      sendWithBufferLevel: 0,
      bufferSize: 100,
      defaultBeforeSend: beforeSendExample,
    });
    logErrorsService.info("Loading app.");

    return (
      <ErrorBoundary>
        <Helmet script={scripts} />
        <Provider store={store}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider
                maxSnack={3}
                hideIconVariant
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                preventDuplicate
              >
                <Router history={history}>
                  <SignalRProvider>
                    <AppUiProvider>
                      <RootLayout>
                        <Routes />
                      </RootLayout>
                    </AppUiProvider>
                    <WidgetZendesk />
                    {/* <WidgetProductFruits /> */}
                  </SignalRProvider>
                  <div id="recaptcha" className="g-recaptcha" data-size="invisible" />
                </Router>
              </SnackbarProvider>
            </ThemeProvider>
          </LocalizationProvider>
        </Provider>
      </ErrorBoundary>
    );
  }
}

export default App;
